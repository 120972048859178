<template>
    <div>
        <div class="container">
            <b-tabs content-class="my-3 pb-5" nav-class="myTabs">
                <b-tab
                    title="EWARD 2024"
                    title-item-class="myActiveTabs"
                    title-link-class="My border-0"
                >
                    <tabProjetos :projects="showProjects" />
                </b-tab>
                <b-tab
                    title="Votados"
                    title-item-class="myActiveTabs"
                    title-link-class="My border-0"
                >
                    <tabProjetosVotados :projects="showVotesProjects" />
                </b-tab>
            </b-tabs>
        </div>

        <Modal />
    </div>
</template>

<script>
// import _ from 'lodash';
import { api } from '@/services.js';
import { mapState, mapMutations } from 'vuex';
import Modal from '@/components/Projetos/Modal.vue';
import tabProjetosVotados from '@/components/Projetos/tabProjetosVotados.vue';
import tabProjetos from '@/components/Projetos/tabProjetos.vue';

export default {
    name: 'Listagem',
    components: {
        Modal,
        tabProjetosVotados,
        tabProjetos,
    },
    data() {
        return {
            ranking: [],
            teste: [
                {
                    votos: [
                        {
                            criterios: {
                                criterio1: '5',
                                criterio2: '5',
                                criterio3: '5',
                                criterio4: '5',
                                criterio5: '5',
                            },
                            total: 50,
                            jurado: 'JURADO TESTE',
                            id: '25',
                        },
                        {
                            criterios: {
                                criterio1: '5',
                                criterio2: '5',
                                criterio3: '5',
                                criterio4: '5',
                                criterio5: '5',
                            },
                            total: 60,
                            jurado: 'JURADO TESTE2',
                            id: '26',
                        },
                        {
                            criterios: {
                                criterio1: '5',
                                criterio2: '5',
                                criterio3: '5',
                                criterio4: '5',
                                criterio5: '5',
                            },
                            total: 50,
                            jurado: 'JURADO TESTE3',
                            id: '27',
                        },
                    ],
                    id_projetos: '18',
                    pro_nome: 'Ticket Car, muito além do abastecimento',
                    pontos: 25,
                },
                {
                    votos: [
                        {
                            criterios: {
                                criterio1: '5',
                                criterio2: '5',
                                criterio3: '5',
                                criterio4: '5',
                                criterio5: '5',
                            },
                            total: 50,
                            jurado: 'JURADO TESTE',
                            id: '25',
                        },
                        {
                            criterios: {
                                criterio1: '5',
                                criterio2: '5',
                                criterio3: '5',
                                criterio4: '5',
                                criterio5: '5',
                            },
                            total: 'aguardando',
                            jurado: 'JURADO TESTE2',
                            id: '26',
                        },
                        {
                            criterios: {
                                criterio1: '5',
                                criterio2: '5',
                                criterio3: '5',
                                criterio4: '5',
                                criterio5: '5',
                            },
                            total: 50,
                            jurado: 'JURADO TESTE3',
                            id: '27',
                        },
                    ],
                    id_projetos: '18',
                    pro_nome: 'Ticket Car, muito além do abastecimento',
                    pontos: 25,
                },
            ],
            fields: [
                {
                    key: 'id_projetos',
                    label: 'Projetos',
                },
                {
                    key: 'votos',
                    label: 'Jurados',
                },
                {
                    key: 'pontos',
                    label: 'Pontos',
                },
            ],
        };
    },
    computed: {
        ...mapState([
            'user',
            'keyLogin',
            'listGeral',
            'listEspecial',
            'listProjects',
        ]),
        jurados() {
            let jurado = [];

            if (this.ranking.length > 0) {
                this.ranking.map((e) => {
                    if (e.votos.length > 0) {
                        e.votos.map((v) => {
                            jurado.push(v.jurado);
                        });
                    }
                });

                jurado = jurado.filter(function(item, pos) {
                    return jurado.indexOf(item) == pos;
                });

                return jurado;
            }

            return null;
        },
        showProjects() {
            if (this.listProjects.length > 0) {
                return this.listProjects.filter((x) => {
                    if (x.votos.criterio1 === null) return x;
                });
            } else {
                return [];
            }
        },
        showVotesProjects() {
            if (this.listProjects.length > 0) {
                return this.listProjects.filter((x) => {
                    if (x.votos.criterio1 !== null) return x;
                });
            } else {
                return [];
            }
        },
    },
    methods: {
        ...mapMutations(['SET_PROJECT_ID']),
        soma(v) {
            let sum = v.votos;
            let arr = [];
            sum.map((e) => {
                let total = e.total;
                total = typeof total === 'number' ? total : 0;
                arr.push(total);
            });

            const reducer = (accumulator, curr) => accumulator + curr;
            const total = arr.reduce(reducer);
            const div = (total / arr.length).toFixed(2);

            return div;
        },
        getRanking() {
            api.post('/', {
                keyAcess: 'oIbfygBUibYcdbNfPsG64tPH80',
                getRanking: true,
                keyLogin: this.keyLogin,
            }).then((response) => {
                this.ranking = response.data.ranking;
            });
        },
        getProjects() {
            this.proEspecial = this.listEspecial;
            this.proGeral = this.listGeral;
        },
        openVote(id) {
            this.SET_PROJECT_ID(id);
            this.$root.$emit('bv::toggle::modal', 'modal', '#btnToggle');
        },
    },
    created() {
        this.getProjects();
        this.getRanking();
    },
    filters: {
        FORMAT(v) {
            return v.toFixed(2);
        },
    },
};
</script>

<style lang="scss">
$cor1: #fc4f65;
$cor2: #ffffff;
$cor3: #f72717;
$cor4: #6d7787;
$cor5: #711cff;

a.nav-link {
    font-family: Montserrat Alternates;
    font-weight: 400;
    font-size: 23px;
    color: #949eaf;

    @media (max-width: 767px) {
        font-size: 18px;
    }

    &:hover {
        color: #00a184;
    }
}

.myTabs {
    border-bottom: 3px solid $cor5 !important;
}

.My.active {
    color: $cor4 !important;
    background: transparent !important;
    border: none !important;
    border-bottom: 3px solid rgb(255, 255, 255) !important;
    border-top: 3px solid $cor5 !important;
    border-right: 3px solid $cor5 !important;
    border-left: 3px solid $cor5 !important;
    border-radius: 20px 20px 0 0 !important;
    margin-bottom: -3px !important;
}

.text {
    font-family: EdenredRegular;
    font-size: 18px;
    font-weight: 400;
    color: $cor4;
    margin-left: 8px;
    line-height: 1.3;

    small {
        font-size: 0.9rem;
        font-style: italic;
        color: rgb(175, 174, 174);
    }

    @media (max-width: 767px) {
        font-size: 15px;
    }
}

.table {
    td,
    tr,
    span {
        min-width: 100px;
        font-family: EdenredRegular;
        color: $cor4;
        font-size: 18px;

        @media (max-width: 767px) {
            font-size: 15px;
            min-width: 50px;
        }
    }

    .w-100 {
        width: 100%;
    }
}

.criterio {
    font-family: gothicb;
    color: $cor4;
}

.btn-ver {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.btn-votar {
    margin-right: 10px;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
</style>
